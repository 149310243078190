import React from "react";

const ExpandChevron = () => {
	return (
		<>
			<span class="material-symbols-outlined">expand_more</span>
		</>
	);
};

export default ExpandChevron;
