export const banners = [
      {
            id: "1",
            title: "Sigma Banner",
            image: "/images/img/banner/solostove.png",
            slug: "shop-now",
            tag: "Minimum 30% off  ",
            tagProduct: "Solostove",
      },
      {
            id: "2",
            title: "Sigma Banner",
            image: "/images/img/banner/fireplace.png",
            slug: "/shop-now",
      },
      {
            id: "3",
            title: "Sigma Banner",
            image: "/images/img/banner/demi.png",
            slug: "/shop-now",
      },
      {
            id: "4",
            title: "Sigma Banner",
            image: "/images/img/banner//Post67-jpg.webp",
            slug: "/shop-now",
            // tag: "Robotic Pool ",
            // tagProduct: "Cleaner",
      },
];
