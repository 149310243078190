import React from "react";
import { SubscribeContainer } from "./styles";
import { Col, Container, Row } from "react-bootstrap";
import CommonButton from "../../../../components/frontend/home/CommonButton";

const SubscribeBox = () => {
      return (
            <>
                  <Container>
                        <SubscribeContainer>
                              <div className="footer__top w-100">
                                    <Row>
                                          <Col lg="6">
                                                <h3 className="footer__top--title mb-0 pb-0">
                                                      Subscribe to Our
                                                      Newsletter!
                                                </h3>
                                          </Col>
                                          <Col lg={6}>
                                                <form className="form">
                                                      <div className="form__group">
                                                            <input
                                                                  type="text"
                                                                  placeholder="Enter Your Email"
                                                            />

                                                            <CommonButton
                                                                  title="subscribe"
                                                                  type="submit"
                                                                  background={
                                                                        "#fff"
                                                                  }
                                                                  color={"#000"}
                                                            />
                                                      </div>
                                                </form>
                                          </Col>
                                    </Row>
                              </div>
                        </SubscribeContainer>
                  </Container>
            </>
      );
};

export default SubscribeBox;
