import React from "react";
import {
      CategoryItemContainer,
      CategoryItemContent,
      CategoryItemIcon,
} from "./styles";
import { Link } from "react-router-dom";

const CategoryItem = ({ category }) => {
      return (
            <>
                  <CategoryItemContainer className="d-flex flex-column align-items-center justify-content-center">
                        <CategoryItemIcon
                              dangerouslySetInnerHTML={{
                                    __html: category?.icon,
                              }}
                        ></CategoryItemIcon>
                        <CategoryItemContent>
                              <Link
                                    to={`/product-category/${category?.slug}`}
                                    className="text-center pb-4"
                              >
                                    <h5>
                                          {category?.subtitle ||
                                                category?.title}
                                    </h5>
                              </Link>
                        </CategoryItemContent>
                  </CategoryItemContainer>
            </>
      );
};

export default CategoryItem;
