import React from "react";
import { OfferBoxContainer } from "./styles";
import { Col, Container, Row } from "react-bootstrap";
import { offers } from "../../../../data/offerList";
import OfferItem from "./components/OfferItem";

const OfferBox = () => {
      return (
            <>
                  <OfferBoxContainer>
                        <Container>
                              <Row className="align-items-center">
                                    {offers?.map((item, index) => (
                                          <Col
                                                key={index}
                                                className="d-flex align-items-center justify-content-center"
                                          >
                                                {item.id % 2 === 0 ? (
                                                      <OfferItem
                                                            slug={item.slug}
                                                            title={item.title}
                                                            image={item.image}
                                                            postion="center"
                                                            slogan={item.slogan}
                                                      />
                                                ) : (
                                                      <OfferItem
                                                            slug={item.slug}
                                                            title={item.title}
                                                            image={item.image}
                                                            position="left"
                                                      />
                                                )}
                                          </Col>
                                    ))}
                              </Row>
                        </Container>
                  </OfferBoxContainer>
            </>
      );
};

export default OfferBox;
