import React from "react";
import { Link } from "react-router-dom";
import { CommonButtonContainer } from "./styles";

const CommonButton = ({ slug, title, background, color }) => {
      return (
            <>
                  <CommonButtonContainer background={background} color={color}>
                        <Link
                              to={slug}
                              className=" align-items-center justify-content-center gap-1"
                        >
                              {title}
                              <span class="material-symbols-outlined">
                                    arrow_right_alt
                              </span>
                        </Link>
                  </CommonButtonContainer>
            </>
      );
};

export default CommonButton;
