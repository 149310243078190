export const offers = [
   {
      id: 1,
      title: 'Fire Place',
      slug: '/fire-place',
      image: '/images/img/offer/bg-1.jpeg'
   },
   {
      id: 2,
      title: 'Camp Stove',
      slogan: 'Up to 30% Off',
      slug: '/fire-place',
      image: '/images/img/offer/bg-2.jpg'
   },
   {
      id: 3,
      title: 'Fire Place',
      slug: '/fire-place',
      image: '/images/img/offer/bg-1.jpeg'
   }
];
