import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

const FormInput = (props) => {
   const { id, type, errorMessage, onChange, ...inputprops } = props;
   const [focused, setFocused] = useState(false);

   const handleFocus = (e) => {
      setFocused(true);
   };

   return (
      <>
         {console.log(type)}
         {type === 'textarea' ? (
            <>
               <Form.Control
                  as={type}
                  className="border-0"
                  rows={7}
                  {...inputprops}
                  onChange={onChange}
                  onBlur={handleFocus}
                  focused={focused.toString()}
               />
               {console.log(focused)}
               <span>*{errorMessage}</span>
            </>
         ) : (
            <>
               <Form.Control
                  type={type}
                  className="border-0"
                  {...inputprops}
                  onChange={onChange}
                  onBlur={handleFocus}
                  focused={focused.toString()}
               />
               <span>*{errorMessage}</span>
            </>
         )}
      </>
   );
};

export default FormInput;
