import React from 'react';
import BreadCrumBox from '../../components/common/BreadCrumbBox';
import { AboutContainer } from './style';
import {
   AboutBoxContainer,
   AboutBoxContent,
   AboutBoxDesc,
   AboutBoxIcon,
   AboutBoxImage,
   AboutBoxItem,
   AboutBoxItemContent,
   AboutBoxWrapper
} from '../Home/components/AboutBox/styles';
import CommonHeading from '../../components/frontend/home/CommonHeading';
import AboutBox, { information } from '../Home/components/AboutBox';
import { Container, Row, Col } from 'react-bootstrap';
import CommonButton from '../../components/frontend/home/CommonButton';
import { TestimonialBox } from '../Home/components';

const AboutPage = () => {
   return (
      <>
         <BreadCrumBox title={'About Us'} />
         <AboutBox />
      </>
   );
};

export default AboutPage;
