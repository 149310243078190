import React from "react";
import {
      BannerContainer,
      BannerContent,
      BannerImage,
      BannerSection,
      BannerWrapper,
} from "./styles";
import { Col, Row } from "react-bootstrap";
import { banners } from "../../../../data/bannerList";
import ShopButton from "../../../../components/frontend/home/ShopButton";

const BannerBox = () => {
      return (
            <>
                  <BannerSection>
                        <BannerWrapper fluid>
                              <Row className="">
                                    <Col lg={6}>
                                          <BannerContainer>
                                                <BannerContent>
                                                      <h3>{banners[0].tag}</h3>
                                                      {banners[0]
                                                            ?.tagProduct && (
                                                            <h3>
                                                                  {
                                                                        banners[0]
                                                                              .tagProduct
                                                                  }
                                                            </h3>
                                                      )}
                                                      <ShopButton
                                                            slug={
                                                                  banners[0]
                                                                        .slug
                                                            }
                                                      />
                                                </BannerContent>
                                                <BannerImage>
                                                      <img
                                                            src={
                                                                  banners[0]
                                                                        .image
                                                            }
                                                            className="img-fluid"
                                                            alt={
                                                                  banners[0]
                                                                        .title
                                                            }
                                                      />
                                                </BannerImage>
                                          </BannerContainer>
                                    </Col>
                                    <Col lg={6}>
                                          <Row className="g-4">
                                                {banners
                                                      .slice(1)
                                                      .map((item, index) =>
                                                            item.id === "4" ? (
                                                                  <Col
                                                                        lg={12}
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <BannerContainer>
                                                                              <BannerContent leftValue="50px">
                                                                                    {item?.tag && (
                                                                                          <h3>
                                                                                                {
                                                                                                      item.tag
                                                                                                }
                                                                                          </h3>
                                                                                    )}
                                                                                    {item?.tagProduct && (
                                                                                          <h3>
                                                                                                {
                                                                                                      item.tagProduct
                                                                                                }
                                                                                          </h3>
                                                                                    )}
                                                                                    <ShopButton
                                                                                          slug={
                                                                                                banners[0]
                                                                                                      .slug
                                                                                          }
                                                                                    />
                                                                              </BannerContent>
                                                                              <BannerImage height="250px">
                                                                                    <img
                                                                                          src={
                                                                                                item.image
                                                                                          }
                                                                                          className="img-fluid"
                                                                                          alt={
                                                                                                item.title
                                                                                          }
                                                                                    />
                                                                              </BannerImage>
                                                                        </BannerContainer>
                                                                  </Col>
                                                            ) : (
                                                                  <Col
                                                                        lg={6}
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <BannerContainer>
                                                                              <BannerContent>
                                                                                    {item?.tag && (
                                                                                          <h3>
                                                                                                {
                                                                                                      item.tag
                                                                                                }
                                                                                          </h3>
                                                                                    )}
                                                                                    <ShopButton
                                                                                          slug={
                                                                                                banners[0]
                                                                                                      .slug
                                                                                          }
                                                                                    />
                                                                              </BannerContent>
                                                                              <BannerImage>
                                                                                    <img
                                                                                          src={
                                                                                                item.image
                                                                                          }
                                                                                          className="img-fluid"
                                                                                          alt={
                                                                                                item.title
                                                                                          }
                                                                                    />
                                                                              </BannerImage>
                                                                        </BannerContainer>
                                                                  </Col>
                                                            )
                                                      )}
                                          </Row>
                                    </Col>
                              </Row>
                        </BannerWrapper>
                  </BannerSection>
            </>
      );
};

export default BannerBox;
