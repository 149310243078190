import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BreadCrumBox from "../../components/common/BreadCrumbBox";
import axios from "axios";
import toast from "react-hot-toast";
import { ProductPageContainer } from "../product/styles";
import {
      CommonContainer,
      CommonWrapper,
} from "../../styles/components/common/CommonStyle";
import { Col, Row } from "react-bootstrap";
import ProductCategory from "../product/components/ProductCategory";
import ProductFilter from "../product/components/ProductFilter";
import ProductList from "../product/components/ProductList/ProductList";
import CategoryDesktopFilter from "./CategoryDesktopFilter";
import {
      CategoryListFilter,
      CategoryListItem,
      CategoryListRemove,
      CategoryListRemoveIcon,
      FilterContainer,
} from "./style";
import CategoryMobileFilter from "./CategoryMobileFilter";
import { DesktopFilter } from "./CategoryMobileFilter/style";

const Category = () => {
      const { categoryId } = useParams();

      const [list, setList] = useState(false);
      const [loading, setLoading] = useState(false);
      const [products, setProducts] = useState([]);
      const [category, setCategory] = useState([]);
      const [categories, setCategories] = useState([]);

      const [selectedCuisines, setSelectedCuisines] = useState([]);
      const [selectedCategories, setSelectedCategories] = useState([]);
      const [sort, setSort] = useState("desc");
      const [totalProducts, setTotalProducts] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/category/${categoryId}/filter`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/category/${categoryId}`,
                        {
                              headers: {
                                    apikey: process.env.REACT_APP_API_KEY,
                              },
                        }
                  )
                  .then((response) => {
                        if (response.data.result === "success") {
                              setCategory(response.data.category);
                              setProducts(response.data.products);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [categoryId]);

      const handleChangeCategory = (id, type) => {
            const updatedCategories = categories.map((item) => {
                  if (type === "category") {
                        return item.id === id
                              ? { ...item, checked: !item.checked }
                              : item;
                  } else if (type === "subCategory") {
                        const updatedSubCategory = item.subCategory.map(
                              (subCategory) => {
                                    return subCategory.id === id
                                          ? {
                                                  ...subCategory,
                                                  checked: !subCategory.checked,
                                            }
                                          : subCategory;
                              }
                        );
                        return { ...item, subCategory: updatedSubCategory };
                  } else if (type === "childCategory") {
                        const updatedSubCategory = item.subCategory.map(
                              (subCategory) => {
                                    const updatedChildCategory =
                                          subCategory.childCategory.map(
                                                (childCategory) => {
                                                      return childCategory.id ===
                                                            id
                                                            ? {
                                                                    ...childCategory,
                                                                    checked: !childCategory.checked,
                                                              }
                                                            : childCategory;
                                                }
                                          );
                                    return {
                                          ...subCategory,
                                          childCategory: updatedChildCategory,
                                    };
                              }
                        );
                        return { ...item, subCategory: updatedSubCategory };
                  }
                  return item;
            });

            setCategories(updatedCategories);
      };
      const changePrice = async () => {
            const filters = [];

            const cuisinesChecked = categories
                  .filter((item) => item.checked)
                  .map((item) => ({
                        id: item.id,
                        title: item.title,
                        type: "category",
                  }));

            const subCategoriesChecked = categories
                  .flatMap((item) =>
                        item.subCategory.filter((subItem) => subItem.checked)
                  )
                  .map((subItem) => ({
                        id: subItem.id,
                        title: subItem.title,
                        type: "subCategory",
                  }));

            const childCategoriesChecked = categories
                  .flatMap((item) =>
                        item.subCategory.flatMap((subItem) =>
                              subItem.childCategory.filter(
                                    (childItem) => childItem.checked
                              )
                        )
                  )
                  .map((childItem) => ({
                        id: childItem.id,
                        title: childItem.title,
                        type: "childCategory",
                  }));

            const allCheckedIds = [
                  ...cuisinesChecked,
                  ...subCategoriesChecked,
                  ...childCategoriesChecked,
            ];

            const selectedCuisinesIds = allCheckedIds.map((item) => item.id);
            setSelectedCuisines(selectedCuisinesIds);
            setSelectedCategories(allCheckedIds);

            if (allCheckedIds.length) {
                  filters.push(`categories=${selectedCuisinesIds.join(",")}`);
            }
            const apiUrl = `${
                  process.env.REACT_APP_SECRET_KEY
            }/api/category/${categoryId}${
                  filters.length > 0 ? "?" + filters.join("&") : "?"
            }${sort ? `&sort=${sort}` : ""}`;
            console.log(apiUrl);

            await axios
                  .get(apiUrl, {
                        headers: {
                              apikey: process.env.REACT_APP_Api_Key,
                        },
                  })
                  .then((response) => {
                        setProducts(response.data.products);
                        setTotalProducts(response.data.totalProducts);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
      };
      useEffect(() => {
            changePrice();
      }, [categories, sort]);

      return (
            <>
                  <BreadCrumBox title={category?.title} />
                  <ProductPageContainer>
                        <CommonWrapper>
                              <CommonContainer>
                                    <Row>
                                          <DesktopFilter className="col-lg-3">
                                                <CategoryDesktopFilter
                                                      categories={categories}
                                                      handleChangeCategory={
                                                            handleChangeCategory
                                                      }
                                                />
                                          </DesktopFilter>
                                          <Col>
                                                <div
                                                      dangerouslySetInnerHTML={{
                                                            __html: category?.description,
                                                      }}
                                                ></div>
                                                <ProductFilter
                                                      setList={setList}
                                                      setProducts={setProducts}
                                                      list={list}
                                                      category={category}
                                                      setSort={setSort}
                                                      sort={sort}
                                                />
                                                <FilterContainer>
                                                      <CategoryMobileFilter
                                                            show={show}
                                                            handleClose={
                                                                  handleClose
                                                            }
                                                            handleShow={
                                                                  handleShow
                                                            }
                                                            sort={sort}
                                                            setSort={setSort}
                                                      >
                                                            <CategoryDesktopFilter
                                                                  categories={
                                                                        categories
                                                                  }
                                                                  handleChangeCategory={
                                                                        handleChangeCategory
                                                                  }
                                                            />
                                                      </CategoryMobileFilter>
                                                </FilterContainer>
                                                <CategoryListFilter>
                                                      {selectedCategories.length >
                                                            0 &&
                                                            selectedCategories.map(
                                                                  (
                                                                        item,
                                                                        index
                                                                  ) => (
                                                                        <li
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <CategoryListItem>
                                                                                    {
                                                                                          item.title
                                                                                    }
                                                                                    <CategoryListRemove
                                                                                          onClick={() =>
                                                                                                handleChangeCategory(
                                                                                                      item.id,
                                                                                                      item.type
                                                                                                )
                                                                                          }
                                                                                    >
                                                                                          <CategoryListRemoveIcon>
                                                                                                <i className="bx bx-x"></i>
                                                                                          </CategoryListRemoveIcon>
                                                                                    </CategoryListRemove>
                                                                              </CategoryListItem>
                                                                        </li>
                                                                  )
                                                            )}
                                                </CategoryListFilter>
                                                <ProductList
                                                      products={products}
                                                      list={list}
                                                />
                                          </Col>
                                    </Row>
                              </CommonContainer>
                        </CommonWrapper>
                  </ProductPageContainer>
            </>
      );
};

export default Category;
